import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from '@mui/material';
import { getPermissionLevel } from '@pn/core/domain/user';
import { getNumberOfIds, WorkspaceItem } from '@pn/core/domain/workspace';
import { LIST_MAX_SIZE, LIST_MAX_SIZE_FREE } from '@pn/core/limits';
import { getGeneralProject } from '@pn/core/operations/project';
import { duplicateWorkspaceItem } from '@pn/core/operations/workspace/crud/duplicateWorkspaceItem';
import { submitWorkspaceItem } from '@pn/core/operations/workspace/crud/submitWorkspaceItem';
import {
  getStyleEditability,
  isEditable,
} from '@pn/core/permissions/editability';
import {
  useCurrentUserStorage,
  useMapStorage,
  useProjectsStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { useConfirmationDialog } from '@pn/ui/context-components/ConfirmationDialog';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { NavRail } from '@pn/ui/custom-components/nav-rail';
import { NavRailItem } from '@pn/ui/custom-components/nav-rail/NavRailItem';
import { useMenu } from '@pn/ui/hooks/useMenu';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { ForwardSlashIcon } from '@pn/ui/icons';
import { ColorfulStarsIcon } from '@pn/ui/icons/ColorfulStarsIcon';
import {
  EditableTypography,
  useEditableTypographyState,
} from '@pn/ui/inputs/EditableTypography';
import { useLibrary } from '@pn/ui/workspace/LibraryProvider';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { notify } from 'src/application/externalDependencies';
import { MAIN_TRAY_WIDTH } from 'src/ui/Main';
import { DeleteConfirmationDialogContent } from 'src/ui/workspace/components/DeleteConfirmationDialogContent';
import { FilterPropertyComponent } from 'src/ui/workspace/components/FilterPropertyComponent';
import { ItemInformation } from 'src/ui/workspace/components/ItemInformation';
import { ListExports } from 'src/ui/workspace/components/ListExports';
import { QueryOptionsComponent } from 'src/ui/workspace/components/QueryOptions';
import { SourceItemSelector } from 'src/ui/workspace/components/SourceItemSelector';
import { DialogModule } from 'src/ui/workspace/dialog-modules';
import { CombinedColorPicker } from 'src/ui/workspace/styling/CombinedColorPicker';
import { StyleControlsAccordion } from 'src/ui/workspace/styling/StyleControlsAccordion';
import { StylingAlert } from 'src/ui/workspace/styling/StylingAlert';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'grid',
    gridTemplateAreas: `
      'nav-rail title'
      'nav-rail divider'
      'nav-rail content'
      'nav-rail actions'
      'nav-rail progress'
    `,
    gridTemplateColumns: 'min-content 1fr',
    gridTemplateRows: 'min-content min-content 1fr min-content min-content',
    position: 'absolute',
    left: 0,
    top: 64,
    height: 'calc(100% - 64px)',
    border: 'none',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    zIndex: zIndex(theme).workspace,
    [theme.breakpoints.up('md')]: {
      width: 80 + 1 + 481,
    },
    '@media print': {
      display: 'none',
    },
  },
  dialogPaper: {
    background: theme.palette.background.paper,
  },
  dialogTitleContainer: {
    gridArea: 'title',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogTitle: {
    // NOTE do not set explicit height, it will break the wrapping
    display: 'inline-flex',
    alignItems: 'center', // centers the icon
    flex: 1, // wraps long titles
    paddingTop: 10, // lines up with Main Panel header
    paddingBottom: 10, // lines up with Main Panel header
    paddingRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
  editableTypography: {
    width: '100%',
    minHeight: 29,
    paddingTop: 3,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  editableTextField: {
    position: 'relative',
    top: 2,
  },
  itemName: {
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  dialogContent: {
    gridArea: 'content',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
    },
  },
  dialogActions: {
    gridArea: 'actions',
    height: 52.5,
    paddingTop: theme.spacing(1.5),
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    [theme.breakpoints.up('md')]: {
      height: 40,
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1), // 4px reserved for the progress bar
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: 'transparent',
    },
  },
  divider: {
    gridArea: 'divider',
  },
  progressIndicator: {
    gridArea: 'progress',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
    },
  },
  saveButton: {
    height: 40,
    width: 80,
  },
}));

type Props = {
  item: WorkspaceItem;
};

export const WorkspaceItemPanel = React.memo(_WorkspaceItemPanel);
function _WorkspaceItemPanel({ item }: Props) {
  const {
    isWorkspaceItemPanelOpen,
    tab,
    setTab,
    closeWorkspaceItemPanel,
    resetCurrentItem,
  } = useWorkspaceItemPanel();
  const { setLibraryRouter } = useLibrary();
  const { handleOpenConfirmationDialog } = useConfirmationDialog();

  const { xsScreen, smScreen } = useScreenSize();
  const { classes } = useStyles();

  const { allWorkspaceItems, idsInWorkspace } = useWorkspaceStorage();
  const { user } = useCurrentUserStorage();
  const { projects } = useProjectsStorage();
  const { mapMode } = useMapStorage();

  const {
    anchorEl,
    handlers: { openMenu, closeMenu },
  } = useMenu();
  const [isInProgress, setIsInProgress] = React.useState(false);

  const primaryLayer = item.map.layers[0];

  const numberOfIds = React.useMemo(() => getNumberOfIds(item), [item]);
  const isEmptyList = item.itemType === 'list' && numberOfIds === 0;
  const limit =
    getPermissionLevel(user?.userPlan) < 2 ? LIST_MAX_SIZE_FREE : LIST_MAX_SIZE;
  const maxIdsExceeded = item.itemType === 'list' && numberOfIds > limit;

  const nameState = useEditableTypographyState(item.name, item.isTemporary);
  const isEditingDisabled = isInProgress || !isEditable(item, user);
  const isStylingDisabled =
    isInProgress || getStyleEditability(item, user) === 'no_editability';
  const isSubmitButtonDisabled =
    isEditingDisabled ||
    isEmptyList ||
    maxIdsExceeded ||
    isEmpty(nameState.editingValue);

  const handleDuplicate = async () => {
    closeMenu();

    if (isNil(user)) {
      return notify('Create an account to save a copy', 'warning');
    }

    setIsInProgress(true);

    const { isError = false } = await duplicateWorkspaceItem({
      item,
      isInWorkspace: idsInWorkspace.includes(item.id),
      user,
    });

    setIsInProgress(false);

    if (!isError) {
      resetCurrentItem();
      closeWorkspaceItemPanel();
    }
  };

  const handleSubmit = async () => {
    setIsInProgress(true);

    const { isError = false } = await submitWorkspaceItem(
      item,
      getGeneralProject(projects)?.id
    );

    setIsInProgress(false);

    if (isError) return;
    if (item.isTemporary) setLibraryRouter(['Personal']);

    closeMenu();
    closeWorkspaceItemPanel(true);
  };

  const handleDelete = () => {
    workspaceActions().remove(item);

    closeMenu();
    closeWorkspaceItemPanel(true);
  };

  const handleDeleteWithConfirmation = () => {
    if (item.isTemporary) {
      return handleDelete();
    }

    closeMenu();

    const projectsWithItem = projects.filter((project) =>
      project.workspaceItemIds.includes(item.id)
    );
    const childrenItems =
      item.itemType !== 'layer'
        ? []
        : allWorkspaceItems.filter(
            (workspaceItem) => workspaceItem.sourceItem?.id === item.id
          );

    handleOpenConfirmationDialog({
      title: 'Delete layer?',
      text: (
        <DeleteConfirmationDialogContent
          itemName={item.name}
          projectsWithItem={projectsWithItem}
          childrenItems={childrenItems}
        />
      ),
      action: () => {
        handleDelete();
      },
    });
  };

  const handleClose = () => {
    resetCurrentItem();
    closeWorkspaceItemPanel();
  };

  return (
    <Container isOpen={isWorkspaceItemPanelOpen} onClose={handleClose}>
      {!smScreen && (
        <NavRail width={MAIN_TRAY_WIDTH}>
          <NavRailItem
            Icon={tab === 'Content' ? ArticleIcon : ArticleOutlinedIcon}
            label="Content"
            selected={tab === 'Content'}
            onClick={() => setTab('Content')}
          />
          <NavRailItem
            Icon={tab === 'Style' ? AutoAwesomeIcon : ColorfulStarsIcon}
            label="Style"
            selected={tab === 'Style'}
            onClick={() => setTab('Style')}
          />
        </NavRail>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        slotProps={{
          paper: {
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          },
        }}
      >
        <MenuItem
          disabled={isEditingDisabled}
          onClick={() => {
            closeMenu();
            nameState.setIsEditing(true);
            setTimeout(() => nameState.inputRef.current?.focus(), 0);
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={item.isTemporary || item.isGlobal}
          onClick={handleDuplicate}
        >
          <ListItemIcon>
            <CopyAllIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={isEditingDisabled}
          onClick={handleDeleteWithConfirmation}
        >
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <Box className={classes.dialogTitleContainer}>
        <DialogTitle className={classes.dialogTitle}>
          Layer
          <ForwardSlashIcon fontSize="medium" color="disabled" />
          <EditableTypography
            singleClickToEdit
            variant="body1"
            classes={{
              typography: classes.editableTypography,
              textField: classes.editableTextField,
            }}
            title="Click to edit"
            disabled={isEditingDisabled}
            value={item.name}
            onSave={(newValue) =>
              workspaceActions().updateName(item.id, newValue)
            }
            {...nameState}
          />
        </DialogTitle>

        <Box mr={2}>
          <IconButton disabled={isInProgress} onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
          {!smScreen && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box px={2}>
        <Divider className={classes.divider} />
      </Box>
      <DialogContent className={classes.dialogContent}>
        {(tab === 'Content' || smScreen) && (
          <>
            <ItemInformation item={item} />

            {!isNil(item.sourceItem) && (
              <Box maxWidth={320}>
                <SourceItemSelector
                  disabled={isEditingDisabled}
                  isSelectorEnabled={
                    item.isTemporary && isEmpty(item.query.filters) // we don't handle filter lists yet
                  }
                  item={item}
                  sourceItem={item.sourceItem}
                />
              </Box>
            )}

            {item.itemType === 'list' && (
              <QueryOptionsComponent
                disabled={isEditingDisabled}
                item={item}
                numberOfIds={numberOfIds}
              />
            )}

            {item.itemType === 'layer' && (
              <FilterPropertyComponent
                item={item}
                filterProperty={item.map.filterProperty}
                isInWorkspace={idsInWorkspace.includes(item.id)}
              />
            )}

            {!xsScreen && item.itemType === 'list' && !item.isTemporary && (
              <ListExports item={item} />
            )}
          </>
        )}

        {(tab === 'Style' || smScreen) && (
          <StylingAlert item={item} user={user} onDuplicate={handleDuplicate} />
        )}

        {(tab === 'Style' || smScreen) &&
          (smScreen ? (
            <Paper variant="outlined" square className={classes.paperContent}>
              {!isNil(primaryLayer) && (
                <CombinedColorPicker
                  item={item}
                  layer={primaryLayer}
                  disabled={isStylingDisabled}
                  updateAll
                />
              )}
            </Paper>
          ) : (
            <StyleControlsAccordion
              item={item}
              disabled={isStylingDisabled}
              mapMode={mapMode}
            />
          ))}

        <DialogModule module={item.module} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {smScreen && (
          <Button disabled={isInProgress} onClick={handleClose}>
            Close
          </Button>
        )}
        {smScreen ? (
          <Button disabled={isSubmitButtonDisabled} onClick={handleSubmit}>
            Save
          </Button>
        ) : (
          <CustomButton
            className={classes.saveButton}
            disabled={isSubmitButtonDisabled}
            onClick={handleSubmit}
          >
            Save
          </CustomButton>
        )}
      </DialogActions>
      {isInProgress ? (
        <LinearProgress className={classes.progressIndicator} />
      ) : (
        <Box height={4} className={classes.progressIndicator} />
      )}
    </Container>
  );
}

function Container(props: {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { children, isOpen, onClose } = props;

  const { classes } = useStyles();
  const { xsScreen, smScreen } = useScreenSize();

  if (smScreen) {
    return (
      <Dialog
        fullScreen={xsScreen}
        fullWidth
        classes={{
          paper: classes.dialogPaper,
        }}
        open={isOpen}
        onClose={onClose}
      >
        {children}
      </Dialog>
    );
  } else {
    return isOpen ? <Box className={classes.container}>{children}</Box> : null;
  }
}
