import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Code } from '@pn/ui/custom-components/Code';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  table: {
    '& td': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: 'none',
    },
  },
  keysCell: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  key: {
    padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
    background: `rgba(0, 0, 0, ${theme.palette.action.hoverOpacity})`,
    fontFamily: 'monospace',
  },
  textContainer: {
    marginTop: theme.spacing(4),
    '& p': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ShortcutsDialog = ({ open, onClose }: Props) => {
  const { classes } = useStyles();

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Keyboard shortcuts</DialogTitle>
      <DialogContent>
        <Table aria-label="keyboard shortcuts" className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.keysCell}>
                <Key>Esc</Key>
              </TableCell>
              <TableCell>Close</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.keysCell}>
                <Key>Ctrl</Key>
                <Key>K</Key>
              </TableCell>
              <TableCell>Search</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.keysCell}>
                <Key>Ctrl</Key>
                <Key>/</Key>
              </TableCell>
              <TableCell>Library search</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.keysCell}>
                <Key>Ctrl</Key>
                <Key>P</Key>
              </TableCell>
              <TableCell>Print map</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.keysCell}>
                <Key>Ctrl</Key>
                <Key>Alt</Key>
                <Key>Q</Key>
              </TableCell>
              <TableCell>Center on layer/feature</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.keysCell}>
                <Key>Ctrl</Key>
                <Key>Alt</Key>
                <Key>A</Key>
              </TableCell>
              <TableCell>Switch between metric/imperial units</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.keysCell}>
                <Key>Ctrl</Key>
                <Key>Alt</Key>
                <Key>L</Key>
              </TableCell>
              <TableCell>Switch between light/dark theme</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Box className={classes.textContainer}>
          <Typography variant="body2">
            Hold <Code>Shift</Code> to use box selection tool.
          </Typography>
          <Typography variant="body2">
            Hold <Code>Ctrl</Code> to start/extend a selection from single
            feature clicks.
          </Typography>
          <Typography variant="body2">
            Hold <Code>Ctrl</Code> <Code>Shift</Code> to use box selection tool
            to extend an existing selection.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

type KeyProps = {
  children: React.ReactNode;
};

// TODO pull out into ui package
const Key = ({ children }: KeyProps) => {
  const { classes } = useStyles();

  return (
    <Paper variant="outlined" className={classes.key}>
      {children}
    </Paper>
  );
};
