import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { dependencies } from '@pn/core/dependencies';
import { formatDataType, type MappingItem } from '@pn/core/domain/data';
import type { GeoBoundingBox } from '@pn/core/domain/geography';
import {
  DataMultiSelectionReason,
  getVisualizableQuery,
  type StreamableQuery,
} from '@pn/core/domain/query';
import { WorkspaceItem, createWorkspaceItem } from '@pn/core/domain/workspace';
import { ApiError } from '@pn/core/errors';
import {
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { findOrThrow } from '@pn/core/utils/logic';
import ab_formations from '@pn/resources/zones/ab_formations.json';
import { apiZoneProvider } from '@pn/services/api/zone/apiZoneProvider';
import { muiColorPalette } from '@pn/services/color/colorPalettes';
import {
  REFERENCE_PT,
  REFERENCE_ZOOM,
  getCanvasBoundingBox,
  getContext,
  toGeoBoundingBox,
  useDrawing,
} from '@pn/services/drawing';
import { murmurhash3 } from '@pn/services/utils/hash';
import assert from 'assert';
import { findLast, isEmpty, isNil, noop, uniq } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
  textDisabled: {
    color: theme.palette.text.disabled,
  },
  success: {
    color: theme.palette.success.main,
    fontWeight: 500,
  },
  alert: {
    width: '100%',
  },
  button: {
    textTransform: 'none',
  },
  list: {
    margin: 0,
    color: theme.palette.text.secondary,
  },
  semiBold: {
    fontWeight: 500,
  },
}));

const entityOptions = uniq([
  ...ab_formations.map((formation) => formation.name),
  // ...geologicTimeScales.map((scale) => scale.name),
]);

type Props = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const ZonalQueryDialog = ({ open, onOpen, onClose }: Props) => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();

  const { drawingState, setDrawingMode, redraw } = useDrawing();

  const [entity, setEntity] = React.useState('');
  const [openRights, setOpenRights] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [isAwaitingArea, setIsAwaitingArea] = React.useState(false);

  let area = findLast(
    Object.values(drawingState.features),
    (feature) =>
      feature.type === 'poly' &&
      feature.subType === 'rectangle_selection' &&
      feature.itemId === ''
  );

  /**
   * Auto-open the dialog when an area is selected.
   */
  React.useEffect(() => {
    if (!isAwaitingArea || isNil(area)) return;

    setIsAwaitingArea(false);
    onOpen();
  }, [isAwaitingArea, area, onOpen]);

  React.useEffect(() => {
    if (!open) return;

    setDrawingMode('select'); // reset selection mode
  }, [open, setDrawingMode]);

  const handleSelectArea = () => {
    setIsAwaitingArea(true);
    setDrawingMode('rectangle_select');
    onClose();
  };

  const handleRemoveArea = () => {
    assert(area, 'Area should be defined to remove');

    delete drawingState.features[area.id];
    delete drawingState.paths[area.id];
    drawingState.order = drawingState.order.filter((id) => id !== area!.id);

    area = undefined;
    redraw();
  };

  const handleSubmit = async () => {
    const { notificationService } = dependencies;

    setInProgress(true);

    try {
      let bbox: GeoBoundingBox | undefined = undefined;
      let query: StreamableQuery | undefined = undefined;
      let mapping: MappingItem[] = [];

      if (!isNil(area)) {
        const ctx = getContext(document.createElement('canvas'));
        const canvasBbox = getCanvasBoundingBox(ctx, area);
        bbox = toGeoBoundingBox(canvasBbox, REFERENCE_PT, REFERENCE_ZOOM);
      }

      if (!openRights && workspaceItemSelected?.dataType === 'mineral_rights') {
        query = getVisualizableQuery(workspaceItemSelected);
        mapping = workspaceItemSelected.mapping;
      }

      const { dataType, internalIds } = await apiZoneProvider.getByZone({
        zone: entity,
        open: openRights,
        bbox,
        query,
        mapping,
      });

      const item = findOrThrow(
        pnWorkspaceItems,
        (item) => item.id === dataType
      );

      if (isEmpty(internalIds)) {
        notificationService.notify(`No results found for ${entity}`);
        setInProgress(false);
        return;
      }

      const colorIndex = murmurhash3(entity) % muiColorPalette.colors.length;

      const newItem = createWorkspaceItem(
        {
          source: 'item',
          sourceItem: item,
          temporarySourceItemId: item.id,
          name: `${formatDataType(dataType, { case: 'sentence', form: 'plural' })}: ${entity}`,
          queryOptions: {
            requestedIds: internalIds,
            multiSelectionReason: DataMultiSelectionReason.List,
          },
          extraStyle: {
            color: muiColorPalette.colors[colorIndex],
          },
        },
        user
      );

      workspaceActions().add(newItem);
      workspaceActions().addToWorkspace(newItem.id);
      workspaceActions().select(newItem.id);

      onClose();
    } catch (error) {
      if (error instanceof ApiError) {
        notificationService.notify(error.message, 'error');
      }
    }

    setInProgress(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={inProgress ? noop : onClose}
    >
      <DialogTitle>Zone Query</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormControlLabel
          control={
            <Switch
              checked={openRights}
              onChange={() => setOpenRights(!openRights)}
            />
          }
          label="Open rights"
        />
        <Autocomplete
          fullWidth
          options={entityOptions}
          value={entity}
          onChange={(_, value) => setEntity(value ?? '')}
          renderInput={(params) => <TextField {...params} label="Formation" />}
        />
        {openRights && isNil(area) && (
          <Alert severity="warning" className={classes.alert}>
            Area is required for querying open rights
          </Alert>
        )}
        <Box display="flex" alignItems="center" gap={1}>
          <Typography>
            Area:{' '}
            <span
              className={isNil(area) ? classes.textDisabled : classes.success}
            >
              {isNil(area) ? 'not selected' : 'selected ✓'}
            </span>
          </Typography>
          {isNil(area) ? (
            <Button className={classes.button} onClick={handleSelectArea}>
              Select new
            </Button>
          ) : (
            <Button className={classes.button} onClick={handleRemoveArea}>
              Remove
            </Button>
          )}
        </Box>
        {!openRights && (
          <>
            <DialogContentText>
              You can apply filters/selection or select a mineral rights list to
              narrow down the search. Select the default{' '}
              <span>Mineral Rights</span> layer to reset.
            </DialogContentText>
            <CurrentModifiers item={workspaceItemSelected} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={inProgress} onClick={onClose}>
          Close
        </Button>
        <Button
          disabled={
            isEmpty(entity) || inProgress || (openRights && isNil(area))
          }
          onClick={handleSubmit}
        >
          Search
        </Button>
      </DialogActions>
      {inProgress ? <LinearProgress /> : <Box height={4} />}
    </Dialog>
  );
};

const CurrentModifiers = (props: { item: WorkspaceItem | undefined }) => {
  const { item } = props;

  const { classes } = useStyles();

  if (isNil(item)) return null;

  const nonNilFilters = item.query.filters.filter(
    (filter) => !isNil(filter.value)
  );

  if (isEmpty(item.query.requestedIds) && isEmpty(nonNilFilters)) return null;

  return (
    <>
      <DialogContentText>Current constraints:</DialogContentText>
      <ul className={classes.list}>
        {!isEmpty(item.query.requestedIds) && (
          <Typography component="li">
            Selected {formatDataType(item.dataType)}:{' '}
            <span className={classes.semiBold}>
              {item.query.requestedIds.length.toLocaleString()}
            </span>
          </Typography>
        )}
        {!isEmpty(nonNilFilters) && (
          <Typography component="li">
            Filters:{' '}
            <span className={classes.semiBold}>
              {nonNilFilters.map((filter) => filter.value).join(', ')}
            </span>
          </Typography>
        )}
      </ul>
    </>
  );
};
