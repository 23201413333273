import { LayerType, type Layer, type LayerStyle } from '@pn/core/domain/layer';
import { getLayerId, type WorkspaceItem } from '@pn/core/domain/workspace';
import { isNil, startCase } from 'lodash-es';
import { _background } from './_background';
import { dispositions } from './dispositions';
import { dispositions_historical } from './dispositions_historical';
import { facilities } from './facilities';
import { grids } from './grids';
import { land_postings } from './land_postings';
import { mineral_rights } from './mineral_rights';
import { pipelines } from './pipelines';
import { pools } from './pools';
import { units } from './units';
import { wells } from './wells';
import { wells_usa } from './wells_usa';

export const pnWorkspaceItems = [
  wells,
  wells_usa,
  pipelines,
  facilities,
  mineral_rights,
  land_postings,
  // land_results,
  units,
  pools,
  dispositions,
  dispositions_historical,
  grids,
  _background,
];

export function generateMapConfig(params: {
  itemId: string;
  type: LayerType;
  style: LayerStyle;
  source: Layer['source'];
  sourceLayer: string;
  renderAsPoints?: boolean;
}): WorkspaceItem['map'] {
  const {
    itemId,
    type,
    style,
    source,
    sourceLayer,
    renderAsPoints = false,
  } = params;

  return {
    layers: [
      {
        id: getLayerId(itemId, itemId, 0),
        name: startCase(itemId),
        type,
        style,
        source,
        sourceLayer,
        renderAsPoints,
      },
    ],
  };
}

export const symbolItemIds = pnWorkspaceItems
  .filter(
    (item) =>
      item.map.layers[0].type === LayerType.Icon ||
      item.map.layers[0].type === LayerType.Text
  )
  .map((item) => item.id);

export const moduleItemIds = pnWorkspaceItems
  .filter((item) => !isNil(item.module))
  .map((item) => item.id)
  .reverse();
