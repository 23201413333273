import { Input, Typography, TypographyProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { isEmpty, isNil, noop } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ variant: Variant }>()((theme, { variant }) => ({
  textField: {
    fontSize: theme.typography[variant].fontSize,
  },
}));

type State = {
  inputRef: React.RefObject<HTMLInputElement>;
  isEditing: boolean;
  editingValue: string;
  setIsEditing: (isEditing: boolean) => void;
  setEditingValue: (editingValue: string) => void;
};

type Props = {
  singleClickToEdit?: boolean;
  classes?: {
    textField?: string;
    typography?: string;
  };
  disabled?: boolean;
  value: string;
  onSave: (newValue: string) => void;
} & TypographyProps &
  State;

export function EditableTypography({
  singleClickToEdit = false,
  classes: extraClasses = {},
  disabled = false,
  value,
  onSave,
  inputRef,
  isEditing,
  editingValue,
  setIsEditing,
  setEditingValue,
  ...props
}: Props) {
  const { classes, cx } = useStyles({
    variant: normalizeVariant(props.variant),
  });

  const handleStartEditing = () => {
    if (!disabled) setIsEditing(true);
  };

  const handleSubmit = (e?: any) => {
    if (isNil(e)) setIsEditing(false);

    if (editingValue.length > 100) {
      // do nothing
    } else {
      onSave(editingValue);
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (['Enter', 'Escape'].includes(event.key)) handleSubmit();
  };

  return isEditing ? (
    <Input
      autoFocus
      type="text"
      size="small"
      fullWidth
      className={cx(classes.textField, extraClasses.textField)}
      error={isEmpty(editingValue) || editingValue.length > 100}
      value={editingValue}
      onChange={(event) => setEditingValue(event.target.value)}
      onKeyUp={handleKeyUp}
      onBlur={handleSubmit}
      inputProps={{ ref: inputRef }}
    />
  ) : (
    <Typography
      onClick={singleClickToEdit ? handleStartEditing : noop}
      onDoubleClick={handleStartEditing}
      {...props}
      className={cx(extraClasses.typography, props.className)}
    >
      {value}
    </Typography>
  );
}

export function useEditableTypographyState(
  value: string,
  initIsEditing = false
): State {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [isEditing, setIsEditing] = React.useState(initIsEditing);
  const [editingValue, setEditingValue] = React.useState(value);

  React.useLayoutEffect(() => {
    setIsEditing((prev) => (prev ? prev : value === ''));
    setEditingValue(value);
  }, [value]);

  return { inputRef, isEditing, editingValue, setIsEditing, setEditingValue };
}

function normalizeVariant(variant: TypographyProps['variant']): Variant {
  switch (variant) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':
    case 'subtitle1':
    case 'subtitle2':
    case 'body1':
    case 'body2':
    case 'caption':
    case 'button':
    case 'overline':
      return variant;
    default:
      return 'body1';
  }
}
