import type { IExportJobMapper } from '@pn/core/mappers/export-job/ports';
import { nullToUndefined } from '@pn/core/utils/logic';
import type { ApiExportJob } from '@pn/services/api/export-job/types';

export const apiExportJobMapper: IExportJobMapper<ApiExportJob> = {
  toDomainExportJob: (apiExportJob) => {
    return {
      id: apiExportJob.id,
      createdAt: apiExportJob.created_at,
      status: apiExportJob.status,
      type: apiExportJob.request.name,
      dataType: apiExportJob.request.query.data_type,
      size: nullToUndefined(apiExportJob.size),
      url: apiExportJob.url,
    };
  },
};
