import { toSIUnit } from '@pn/core/domain/units';
import type { IProductionItemMapper } from '@pn/core/mappers/production/ports';
import { nullToUndefined } from '@pn/core/utils/logic';
import type { ApiProductionItem } from './types';

export const apiProductionItemMapper: IProductionItemMapper<ApiProductionItem> =
  {
    toDomainProductionItem: (apiProductionItem) => {
      return {
        date: apiProductionItem.date,
        fluidType: convertFluidType(apiProductionItem.fluid_type),
        productionGrouping: apiProductionItem.production_grouping,
        producingDaily: toSIUnit({
          value: nullToUndefined(apiProductionItem.volume_producing_daily),
          symbol: apiProductionItem.volume_units + '/d',
        }),
        calendarDaily: toSIUnit({
          value: nullToUndefined(apiProductionItem.volume_calendar_daily),
          symbol: apiProductionItem.volume_units + '/d',
        }),
        monthly: toSIUnit({
          value: nullToUndefined(apiProductionItem.volume_monthly),
          symbol: apiProductionItem.volume_units,
        }),
        cumulative: toSIUnit({
          value: nullToUndefined(apiProductionItem.volume_cumulative),
          symbol: apiProductionItem.volume_units,
        }),
        hours: nullToUndefined(apiProductionItem.hours),
        count: nullToUndefined(apiProductionItem.well_count),
        id: nullToUndefined(apiProductionItem.wellbore_uwi),
        itemType: apiProductionItem.item_type ?? 'item',
      };
    },
  };

function convertFluidType(fluid_type: string | undefined) {
  switch (fluid_type) {
    case 'PRODUCTION':
      return 'production';
    case 'INJECTION':
      return 'injection';
    default:
      return 'other';
  }
}
