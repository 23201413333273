import { dependencies } from '@pn/core/dependencies';
import type { GeoPoint } from '@pn/core/domain/geography';
import { isMapMode, MapMode } from '@pn/core/domain/map';
import { mapActions, workspaceActions } from '@pn/core/storage';
import { defaultWorkspace } from '@pn/core/storage/workspace/defaultWorkspaces';
import { symbolItemIds } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { setIsDone } from '@pn/services/map/mapbox/methods/initialize';
import { mapModeDefaultColors } from '@pn/services/styles';
import type { PNTheme } from '@pn/ui/theme/PetroNinjaThemeProvider';
import React from 'react';

type MapInitializationOptions = {
  pnTheme?: PNTheme;
  zoom?: number;
  center?: GeoPoint;
};

export function useInitializeMap(options: MapInitializationOptions = {}) {
  const mapRef = React.useRef<HTMLDivElement | undefined>();

  React.useEffect(() => {
    if (!mapRef.current) return;

    const lsMapMode = localStorage.getItem('pn-map-mode');
    const initMapMode = isMapMode(lsMapMode) ? lsMapMode : MapMode.Standard;

    const controller = new window.AbortController();

    dependencies.map
      .initialize(mapRef.current, controller.signal, {
        initMapMode,
        ...options,
      })
      .then(() => {
        mapActions().updateMode(initMapMode);
        mapActions().initialize();

        /**
         * Update colors of all symbol PN layers.
         */
        symbolItemIds.forEach((itemId) =>
          workspaceActions().updateAllLayerStyles(itemId, {
            color: mapModeDefaultColors[initMapMode],
          })
        );
      })
      .catch(() => {}); // abort

    return () => {
      mapActions().reset();
      workspaceActions().setWorkspace(defaultWorkspace);
      setIsDone(false);
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { mapRef };
}
