import { dependencies } from '@pn/core/dependencies';
import { DataMultiSelectionReason } from '@pn/core/domain/query';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { createSourceItemMapConfig } from '@pn/core/domain/workspace';
import { ApiError, getApiErrorMessage } from '@pn/core/errors';
import { handleError } from '@pn/core/errors/handleError';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { projectsActions, workspaceActions } from '@pn/core/storage';
import { generateTemporaryId } from '@pn/core/utils/id';
import { apiListMapper } from '@pn/services/api/list/apiListMapper';
import { cloneDeep, isEmpty, isNil } from 'lodash-es';

export async function submitWorkspaceItem(
  item: WorkspaceItem,
  generalProjectId: string | undefined
): Promise<{
  isError?: boolean;
}> {
  const { apiClient } = dependencies;

  if (item.isTemporary) {
    if (item.itemType === 'layer') {
      workspaceActions().update({
        ...item,
        name: item.name.trim(),
        isTemporary: false,
      });

      return {};
    }

    /**
     * Create a new item from the temporary one.
     */
    const itemCopy: WorkspaceItem = cloneDeep({
      ...item,
      id: generateTemporaryId(),
      isTemporary: false,
      name: item.name.trim(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });

    /**
     * Convert filters to requestedIds before storing the item as a list.
     */
    if (item.itemType === 'list' && !isEmpty(item.query.filters)) {
      itemCopy.query.requestedIds = getMapDataItems(item).map(({ _id }) => _id);
      itemCopy.query.filters = [];
      itemCopy.query.filtersLinkOperator = 'and';
    }

    try {
      const response = await apiClient.request<{
        id: string;
        found: string[];
        missing: string[];
        stack_url: string | null;
      }>({
        method: 'POST',
        url: 'v2/lists',
        payload: apiListMapper().toOriginalItem(itemCopy),
      });

      const updatedItem: WorkspaceItem = {
        ...itemCopy,
        id: response.id,
        numberOfElements: response.found.length,
        map: createSourceItemMapConfig({
          mode: 'duplicate',
          sourceItem: item,
          newItemId: response.id,
        }),
        query: {
          ...itemCopy.query,
          id: response.id,
          requestedIds: response.found,
          multiSelectionReason: DataMultiSelectionReason.List,
        },
        isProcessed: false,
      };

      workspaceActions().remove(item);
      workspaceActions().removeFromWorkspace(item.id);
      workspaceActions().unsetRequestedDataItemId(item.id);

      workspaceActions().create(updatedItem);
      workspaceActions().addToWorkspace(updatedItem.id);
      workspaceActions().select(updatedItem.id);

      workspaceActions().updateInvalidDataItemIds(response.missing);

      if (updatedItem._isTemporaryShared && !isNil(generalProjectId)) {
        projectsActions().addItem(generalProjectId, updatedItem);
      }

      return {};
    } catch (error) {
      if (error instanceof ApiError) {
        handleError({ error, userFriendlyMessage: getApiErrorMessage(error) });
      } else {
        handleError({
          error,
          userFriendlyMessage: 'Failed to save this layer',
        });
      }

      return { isError: true };
    }
  } else {
    workspaceActions().update({ ...item, name: item.name.trim() });

    return {};
  }
}
