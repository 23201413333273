import { UnitSystem } from '@pn/core/domain/types';
import type { PartialRecord } from '@pn/core/utils/typescript';

export const conversionTable: PartialRecord<
  string,
  {
    [key in UnitSystem]?: {
      operation: (value: number) => number;
      symbol: string;
    };
  }
> = {
  /* Volume */

  m3: {
    [UnitSystem.Imperial]: {
      operation: (v) => v * 6.2898,
      symbol: 'bbl',
    },
  },
  bbl: {
    [UnitSystem.Metric]: {
      operation: (v) => v * 0.15899,
      symbol: 'm3',
    },
  },

  'm3/d': {
    [UnitSystem.Imperial]: {
      operation: (v) => v * 6.2898,
      symbol: 'bbl/d',
    },
  },
  'bbl/d': {
    [UnitSystem.Metric]: {
      operation: (v) => v * 0.15899,
      symbol: 'm3/d',
    },
  },

  e3m3: {
    [UnitSystem.Imperial]: {
      operation: (v) => v * 35.315,
      symbol: 'mcf',
    },
  },
  mcf: {
    [UnitSystem.Metric]: {
      operation: (v) => v * 0.028317,
      symbol: 'e3m3',
    },
  },

  'e3m3/d': {
    [UnitSystem.Imperial]: {
      operation: (v) => v * 35.315,
      symbol: 'mcf/d',
    },
  },
  'mcf/d': {
    [UnitSystem.Metric]: {
      operation: (v) => v * 0.028317,
      symbol: 'e3m3/d',
    },
  },

  // scf: {
  //   [UnitSystem.Metric]: {
  //     operation: (v) => v * 0.028317,
  //     symbol: 'm3',
  //   },
  // },
  // 'scf/d': {
  //   [UnitSystem.Metric]: {
  //     operation: (v) => v * 0.028317,
  //     symbol: 'm3/d',
  //   },
  // },

  /* Length */

  m: {
    [UnitSystem.Imperial]: {
      operation: (v) => v * 3.2808,
      symbol: 'ft',
    },
  },
  ft: {
    [UnitSystem.Metric]: {
      operation: (v) => v * 0.3048,
      symbol: 'm',
    },
  },

  mm: {
    [UnitSystem.Imperial]: {
      operation: (v) => v / 25.4,
      symbol: 'in',
    },
  },
  in: {
    [UnitSystem.Metric]: {
      operation: (v) => v * 25.4,
      symbol: 'mm',
    },
  },

  /* Pressure */

  kPa: {
    [UnitSystem.Imperial]: {
      operation: (v) => v * 0.14504,
      symbol: 'psi',
    },
  },
  psi: {
    [UnitSystem.Metric]: {
      operation: (v) => v * 6.8948,
      symbol: 'kPa',
    },
  },

  /* Temperature */

  C: {
    [UnitSystem.Imperial]: {
      operation: (v) => (v * 9) / 5 + 32,
      symbol: 'F',
    },
  },
  F: {
    [UnitSystem.Metric]: {
      operation: (v) => ((v - 32) * 5) / 9,
      symbol: 'C',
    },
  },

  /* Density */

  'kg/m3': {
    [UnitSystem.Imperial]: {
      operation: (v) => v * 0.062428,
      symbol: 'lb/ft3',
    },
  },
  'lb/ft3': {
    [UnitSystem.Metric]: {
      operation: (v) => v * 16.018,
      symbol: 'kg/m3',
    },
  },
};
