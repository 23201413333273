import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';
import { Document } from '@pn/core/domain/data-info';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { useAccess } from '@pn/core/permissions/access';
import { useAutoGetDataDocuments } from '@pn/core/providers/data-info/getDataDocuments';
import { useDataDocumentsStorage, useWorkspaceStorage } from '@pn/core/storage';
import { isCordova } from '@pn/core/utils/env';
import { EmptyStateWithIcon } from '@pn/ui/custom-components/EmptyStateWithIcon';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import { StyledTreeItem } from '@pn/ui/custom-components/tree-view/StyledTreeItem';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import download from 'downloadjs';
import JSZip from 'jszip';
import {
  difference,
  filter,
  find,
  intersection,
  isEmpty,
  isNil,
  sortBy,
  union,
  uniq,
} from 'lodash-es';
import React from 'react';
import {
  apiClient,
  notificationService,
  useAuthenticationService,
} from 'src/application/externalDependencies';
import { AuthLink } from 'src/ui/components/AuthLink';
import { FullHeightCircularLoader } from 'src/ui/components/FullHeightCircularLoader';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  documentsContainer: {
    overflowY: 'auto',
    maxHeight: '600px',
  },
  panelContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  bottomContainer: {
    marginTop: theme.spacing(2),
  },
  panelTypography: {
    marginRight: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
  },
  addButton: {
    height: 40,
  },
}));

type Props = {
  item: WorkspaceItem;
};

export function Documents({ item }: Props) {
  const { smScreen } = useScreenSize();
  const { classes } = useStyles();

  const isMobile = smScreen || isCordova();

  const access = useAccess();

  const { dataItemRequested } = useWorkspaceStorage();
  const { isFetching, documents = [] } = useDataDocumentsStorage(item.dataType);

  const folders = uniq(documents.map((d) => d.folder)).filter(
    (f): f is NonNullable<typeof f> => !isNil(f)
  );

  const { isAuthenticated } = useAuthenticationService();

  useAutoGetDataDocuments(item.dataType, dataItemRequested.id);

  const [filesSelected, setFilesSelected] = React.useState<string[]>([]);
  const [loadingFiles, setLoadingFiles] = React.useState(false);
  const [progressStage, setProgressStage] = React.useState('Initializing...');
  const [progressCount, setProgressCount] = React.useState(0);

  React.useLayoutEffect(() => {
    if (isFetching) setFilesSelected([]);
  }, [isFetching]);

  const handleSelect = (checked: boolean, url: string) => {
    if (checked) {
      setFilesSelected(union(filesSelected, [url]));
    } else {
      setFilesSelected(difference(filesSelected, [url]));
    }
  };

  const handleSelectAll = (checked: boolean, folder: Document['folder']) => {
    const docs = filter(documents, (d) => d.folder === folder);
    const fileUrls = docs.map((d) => d.url);
    if (checked) {
      setFilesSelected(difference(filesSelected, fileUrls));
    } else {
      setFilesSelected(union(filesSelected, fileUrls));
    }
  };

  const handleDownload = async () => {
    setLoadingFiles(true);
    setProgressStage('Initializing...');
    setProgressCount(0);

    const openExternalFile = (url: string) => {
      return fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          return { url, blob };
        });
    };

    try {
      setProgressStage('Generating signed URLs...');
      const fileNames = filesSelected
        .map((fileKey) => find(documents, (d) => d.url === fileKey)?.name)
        .filter((name): name is NonNullable<typeof name> => !isNil(name));
      const requests = filesSelected.map((fileKey) => getSecureS3File(fileKey));
      const responses = await Promise.all(requests);
      const signedUrls = responses.filter(
        (r): r is NonNullable<typeof r> => !isNil(r)
      );

      const zip = new JSZip();

      setProgressStage('Fetching files...');
      const filesRequests = signedUrls.map((url) =>
        openExternalFile(url).then((file) => {
          setProgressCount((count) => count + 1);
          return file;
        })
      );
      const files = await Promise.all(filesRequests);

      setProgressStage('Creating downloadable archive...');
      files.forEach((file, index) => {
        zip.file(fileNames[index], file.blob);
      });

      zip.generateAsync({ type: 'blob' }).then((content) => {
        if (isCordova()) {
          notificationService.notify(
            'Unable to download zip archives in the app. Please use mobile/desktop browser.',
            'error'
          );
        } else {
          download(content, dataItemRequested.id + '.zip', 'application/zip');
        }
      });
    } catch (err) {
      console.error(err);
      notificationService.notify(
        'Download failed. Please try again later.',
        'error'
      );
    }

    setLoadingFiles(false);
  };

  const getSecureS3File = async (fileKey: string) => {
    try {
      const response = await apiClient.request<{ signedUrl: string }>({
        url: 'v1/document/' + encodeURIComponent(fileKey),
      });

      return response.signedUrl;
    } catch (error) {
      handleError({
        error,
        userFriendlyMessage: 'Failed to fetch secure file URL',
      });
    }
  };

  const openSecureS3File = (fileKey: string) => {
    if (access('documents.export').notify().denied()) return;

    getSecureS3File(fileKey).then((signedUrl) => {
      if (isNil(signedUrl)) return;

      if (isCordova()) {
        window.cordova.InAppBrowser.open(signedUrl, '_system');
      } else {
        window.open(signedUrl, '_blank');
      }
    });
  };

  if (!isAuthenticated) {
    return (
      <EmptyStateWithIcon
        variant="filled"
        icon={LockIcon}
        text={
          <span>
            Please <AuthLink type="login">login</AuthLink> or{' '}
            <AuthLink type="signup">sign up</AuthLink> to view documents
          </span>
        }
      />
    );
  }

  if (isFetching) {
    return <FullHeightCircularLoader />;
  }

  return (
    <>
      <Box className={classes.container}>
        {isEmpty(documents) ? (
          <EmptyStateWithIcon
            variant="filled"
            icon={InsertDriveFileIcon}
            text="No documents found"
          />
        ) : (
          <Box className={classes.documentsContainer}>
            <SimpleTreeView
              defaultExpandedItems={['public-documents']}
              disableSelection
              slots={{
                expandIcon: FolderIcon,
                collapseIcon: FolderOpenIcon,
              }}
            >
              {!isEmpty(documents) && (
                <StyledTreeItem
                  itemId="public-documents"
                  labelText="Public documents"
                >
                  {sortBy(folders).map((folder) => {
                    const folderDocs = documents.filter(
                      (d) => d.folder === folder
                    );
                    const folderDocsKeys = folderDocs.map((d) => d.url);

                    if (isNil(folderDocsKeys)) return null;

                    const allFolderFilesSelected =
                      intersection(filesSelected, folderDocsKeys).length ===
                      folderDocsKeys.length;

                    const someFolderFilesSelected =
                      intersection(filesSelected, folderDocsKeys).length > 0;

                    return (
                      <StyledTreeItem
                        key={folder}
                        itemId={folder}
                        labelText={folder}
                        indeterminate={
                          someFolderFilesSelected && !allFolderFilesSelected
                        }
                        isChecked={allFolderFilesSelected}
                        onToggle={
                          isMobile
                            ? undefined
                            : () =>
                                handleSelectAll(
                                  allFolderFilesSelected ||
                                    someFolderFilesSelected,
                                  folder
                                )
                        }
                      >
                        {folderDocs.map((doc) => (
                          <StyledTreeItem
                            key={doc.name}
                            itemId={doc.name}
                            labelText={doc.name}
                            isChecked={filesSelected.includes(doc.url)}
                            onToggle={
                              isMobile
                                ? undefined
                                : (checked) => handleSelect(checked, doc.url)
                            }
                            onDownload={
                              isMobile
                                ? () => openSecureS3File(doc.url)
                                : undefined
                            }
                          />
                        ))}
                      </StyledTreeItem>
                    );
                  })}
                </StyledTreeItem>
              )}
            </SimpleTreeView>
          </Box>
        )}

        {!isMobile && (
          <Box className={classes.bottomContainer}>
            <Box className={classes.panelContainer}>
              <Typography
                color="textSecondary"
                className={classes.panelTypography}
              >
                Files selected: {filesSelected.length.toLocaleString()}
              </Typography>
              <Box ml={2} />
              <PermissionButton
                permissionPath="documents.export"
                variant="contained"
                color="secondary"
                disabled={isEmpty(filesSelected) || loadingFiles}
                onClick={handleDownload}
              >
                Download
              </PermissionButton>
            </Box>
          </Box>
        )}
      </Box>

      <Dialog open={loadingFiles} fullWidth>
        <DialogTitle>Preparing</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography>{progressStage}</Typography>
          </Box>

          <LinearProgress
            variant="determinate"
            value={(progressCount / filesSelected.length) * 100}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
