import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import {
  createDrawingMapConfig,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { ApiError, getApiErrorMessage } from '@pn/core/errors';
import { handleError } from '@pn/core/errors/handleError';
import { getGeneralProject } from '@pn/core/operations/project';
import { isEditable } from '@pn/core/permissions/editability';
import {
  projectsActions,
  useCurrentUserStorage,
  useProjectsStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { apiAnnotationMapper } from '@pn/services/api/annotation/apiAnnotationMapper';
import { useDrawing } from '@pn/services/drawing';
import { drawItem } from '@pn/services/drawing/hooks/useVisualizeDrawingItems';
import { useConfirmationDialog } from '@pn/ui/context-components/ConfirmationDialog';
import { useMenu } from '@pn/ui/hooks/useMenu';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import {
  EditableTypography,
  useEditableTypographyState,
} from '@pn/ui/inputs/EditableTypography';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';
import { clone, isEmpty, isNil, noop } from 'lodash-es';
import { apiClient, notify } from 'src/application/externalDependencies';
import { DrawingPanelControls } from 'src/ui/drawing/DrawingPanelControls';
import { ItemInformation } from 'src/ui/workspace/components/ItemInformation';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

export const DRAWING_PANEL_WIDTH = 370;

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'grid',
    gridTemplateAreas: `
      'title'
      'divider'
      'content'
      'actions'
    `,
    gridTemplateRows: 'min-content min-content 1fr min-content',
    position: 'absolute',
    left: theme.spacing(2),
    top: `calc(64px + ${theme.spacing(2)})`,
    width: DRAWING_PANEL_WIDTH,
    background: theme.palette.background.paper,
    zIndex: zIndex(theme).workspace,
    '@media print': {
      display: 'none',
    },
  },
  dialogTitleContainer: {
    gridArea: 'title',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogTitle: {
    // NOTE do not set explicit height, it will break the wrapping
    display: 'inline-flex',
    alignItems: 'center',
    flex: 1, // wraps long titles
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
  editableTypography: {
    width: '100%',
    minHeight: 29,
    paddingTop: 3,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  editableTextField: {
    position: 'relative',
    top: 2,
  },
  itemName: {
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  dialogContent: {
    gridArea: 'content',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
  dialogActions: {
    gridArea: 'actions',
    height: 40,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  divider: {
    gridArea: 'divider',
  },
  saveButton: {
    height: 40,
    width: 80,
  },
}));

type Props = {
  item: WorkspaceItem;
};

export const DrawingPanel = ({ item }: Props) => {
  const { classes } = useStyles();
  const { smScreen } = useScreenSize();

  const { user } = useCurrentUserStorage();
  const { workspaceItems } = useWorkspaceStorage();
  const { projects } = useProjectsStorage();

  const { closeWorkspaceItemPanel, resetCurrentItem } = useWorkspaceItemPanel();
  const { handleOpenConfirmationDialog } = useConfirmationDialog();
  const { drawingState, historyManager, redraw, reset } = useDrawing();

  const isEditingDisabled = !isEditable(item, user);

  const {
    anchorEl,
    handlers: { openMenu, closeMenu },
  } = useMenu();

  const nameState = useEditableTypographyState(item.name, item.isTemporary);

  const handleSubmit = async () => {
    const itemCopy = {
      ...item,
      name: item.name.trim(),
      numberOfElements: Object.values(drawingState.features).filter(
        (feature) => feature.itemId === item.id
      ).length,
      map: createDrawingMapConfig({
        id: item.id,
        data: {
          type: 'drawing',
          features: drawingState.order
            .map((id) => clone(drawingState.features[id])) // cloning fixes setVisibility crashing on newly updated items
            .filter((feature) => feature.itemId === item.id),
        },
      }),
    };

    if (item.isTemporary) {
      try {
        const { id: newId } = await apiClient.request<{ id: string }>({
          method: 'POST',
          url: 'v2/annotations',
          payload: apiAnnotationMapper().toOriginalItem(itemCopy),
        });

        const layer = itemCopy.map.layers[0];
        assert(layer.metadata, 'metadata must be defined in the drawing item');
        layer.metadata.features.forEach((feature: CanvasFeature) => {
          feature.itemId = newId;
          feature.isVisible = true;
        });

        const updatedItem: WorkspaceItem = {
          ...itemCopy,
          isTemporary: false,
          id: newId,
          dataType: newId,
          query: {
            ...itemCopy.query,
            dataType: newId,
          },
        };

        /* Reset the canvas and redraw all active workspace drawings */
        reset();
        workspaceItems
          .filter(({ itemType }) => itemType === 'drawing')
          .forEach((item) => drawItem(item, drawingState));
        redraw();

        workspaceActions().remove(item);
        workspaceActions().removeFromWorkspace(item.id);

        workspaceActions().create(updatedItem);
        workspaceActions().addToWorkspace(updatedItem.id);

        const generalProjectId = getGeneralProject(projects)?.id;
        if (updatedItem._isTemporaryShared && !isNil(generalProjectId)) {
          projectsActions().addItem(generalProjectId, updatedItem);
        }
      } catch (error) {
        if (error instanceof ApiError) {
          handleError({
            error,
            userFriendlyMessage: getApiErrorMessage(error),
          });
        } else {
          handleError({
            error,
            userFriendlyMessage: 'Failed to save this drawing',
          });
        }
      }
    } else {
      workspaceActions().update(itemCopy);
    }

    notify('Drawing saved!');
    closeWorkspaceItemPanel(true);

    /* Reset selection */
    drawingState.featuresSelected = {};
    redraw();
  };

  const handleDelete = () => {
    workspaceActions().remove(item);

    closeMenu();
    closeWorkspaceItemPanel(true);
  };

  const handleClose = () => {
    if (historyManager.length() > 0) {
      handleOpenConfirmationDialog({
        title: 'Exit annotation editor?',
        text: 'All unsaved changes will be lost.',
        action: () => {
          if (item.isTemporary) workspaceActions().remove(item);
          else resetCurrentItem();
          closeWorkspaceItemPanel();

          /* Reset the canvas and redraw all active workspace drawings */
          reset();
          workspaceItems
            .filter(({ itemType }) => itemType === 'drawing')
            .forEach((item) => drawItem(item, drawingState));
          redraw();
        },
      });
    } else {
      if (item.isTemporary) workspaceActions().remove(item);
      else resetCurrentItem();
      closeWorkspaceItemPanel();
    }
  };

  return (
    <Paper variant="outlined" className={classes.container}>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        slotProps={{
          paper: {
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          },
        }}
      >
        <MenuItem
          disabled={isEditingDisabled}
          onClick={() => {
            closeMenu();
            nameState.setIsEditing(true);
            setTimeout(() => nameState.inputRef.current?.focus(), 0);
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={isEditingDisabled}
          onClick={() => {
            closeMenu();
            handleDelete();
          }}
        >
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <Box className={classes.dialogTitleContainer}>
        <DialogTitle className={classes.dialogTitle}>
          <EditableTypography
            singleClickToEdit
            variant="body1"
            classes={{
              typography: classes.editableTypography,
              textField: classes.editableTextField,
            }}
            title="Click to edit"
            disabled={isEditingDisabled}
            value={item.name}
            onSave={(newValue) =>
              workspaceActions().updateName(item.id, newValue)
            }
            {...nameState}
          />
        </DialogTitle>

        <Box mr={2}>
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
          {!smScreen && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box px={2}>
        <Divider className={classes.divider} />
      </Box>
      <DialogContent className={classes.dialogContent}>
        <ItemInformation item={item} />

        <DrawingPanelControls />

        <Alert severity="info">
          Learn about the new drawing tools{' '}
          <Link
            component="a"
            href="https://help.petroninja.com/annotations/new"
            target="_blank"
          >
            here
          </Link>
          . Starting Oct 15th, 2024 this feature will only be available under
          our paid{' '}
          <Link
            component="a"
            href="https://about.petroninja.com/plans"
            target="_blank"
          >
            plans
          </Link>
          .
        </Alert>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {smScreen && <Button onClick={noop}>Close</Button>}
        <Button
          id="save-drawing-button"
          disabled={isEditingDisabled || isEmpty(item.name)}
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
      </DialogActions>
    </Paper>
  );
};
