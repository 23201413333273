import CancelIcon from '@mui/icons-material/Cancel';
import PaletteIcon from '@mui/icons-material/Palette';
import PrintIcon from '@mui/icons-material/Print';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import {
  Alert,
  Badge,
  Box,
  Fab,
  FabProps,
  Popover,
  Tooltip,
} from '@mui/material';
import { createDrawing } from '@pn/core/operations/workspace/crud/createDrawing';
import { useAccess } from '@pn/core/permissions/access';
import { useCurrentUserStorage, useWorkspaceStorage } from '@pn/core/storage';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isCordova, isFreeCordova, isTouchDevice } from '@pn/core/utils/env';
import { useDrawing } from '@pn/services/drawing';
import { useAnnotations } from '@pn/ui/annotations/AnnotationProvider';
import { Code } from '@pn/ui/custom-components/Code';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { CircleSelectIcon, PolygonSelectIcon } from '@pn/ui/icons';
import { useLibrary } from '@pn/ui/workspace/LibraryProvider';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '@media print': {
      display: 'none',
    },
  },
  badge: {
    zIndex: theme.zIndex.fab,
  },
  // TODO remove after 2024-09-15
  code: {
    padding: `1px 6px`,
  },
}));

export const MapButtons = () => {
  const { classes } = useStyles();
  const { smScreen } = useScreenSize();

  const access = useAccess();
  const isAccessDenied = access('annotations').denied();

  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();

  const { isAnnotationInterfaceOpen, drawMode, setDrawMode } = useAnnotations();
  const { drawingMode, setDrawingMode, redraw, reset } = useDrawing();
  const { closeLibrary } = useLibrary();
  const { isDrawingPanelOpen, openWorkspaceItemPanel } =
    useWorkspaceItemPanel();

  const isPolygonToolActive =
    !isAnnotationInterfaceOpen && drawMode === 'draw_annotation_polygon';
  const isCircleToolActive =
    !isAnnotationInterfaceOpen && drawMode === 'draw_annotation_circle';

  useHotkeys(
    'esc',
    () => {
      if (isPolygonToolActive || isCircleToolActive) {
        setDrawMode('simple_select');
      }
    },
    [isPolygonToolActive, isCircleToolActive]
  );

  const actions = [
    {
      tooltip: isPolygonToolActive ? 'Cancel' : 'Select (Polygon)',
      color: isPolygonToolActive ? 'secondary' : 'default',
      disabled: isAccessDenied,
      ariaLabel: 'polygon selection',
      onClick: (_e: React.MouseEvent<HTMLButtonElement>) =>
        isPolygonToolActive
          ? setDrawMode('simple_select')
          : setDrawMode('draw_annotation_polygon'),
      icon: isPolygonToolActive ? <CancelIcon /> : <PolygonSelectIcon />,
    },
    {
      tooltip: isCircleToolActive ? 'Cancel' : 'Select (Circle)',
      color: isCircleToolActive ? 'secondary' : 'default',
      disabled: isAccessDenied,
      ariaLabel: 'circle selection',
      onClick: (_e: React.MouseEvent<HTMLButtonElement>) =>
        isCircleToolActive
          ? setDrawMode('simple_select')
          : setDrawMode('draw_annotation_circle'),
      icon: isCircleToolActive ? <CancelIcon /> : <CircleSelectIcon />,
    },
  ];

  // TODO keep free or make professional+ after 2024-10-15?
  actions.push({
    tooltip: 'Distance',
    color: drawingMode === 'distance' ? 'secondary' : 'default',
    disabled: false,
    ariaLabel: 'measure distance',
    onClick: (_e: React.MouseEvent<HTMLButtonElement>) => {
      if (drawingMode === 'distance') {
        setDrawingMode('select');

        /* Reset all temporary drawings on canvas */
        reset('');
        redraw();
      } else {
        setDrawingMode('distance');
      }
    },
    icon: <SquareFootIcon />,
  });

  // TODO remove after 2024-10-01
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  React.useEffect(() => {
    const onBeforePrint = () => setAnchorEl(null);

    window.addEventListener('beforeprint', onBeforePrint);

    return () => {
      window.removeEventListener('beforeprint', onBeforePrint);
    };
  }, []);
  if (!isCordova() && new Date() <= new Date('2024-10-01')) {
    actions.unshift({
      tooltip: 'Print',
      color: 'default',
      disabled: false,
      ariaLabel: 'print',
      onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(e.currentTarget),
      icon: <PrintIcon />,
    });
  }

  const handleCreate = () => {
    if (access('drawings').notify().denied()) return;
    assert(user, 'User must be defined to create a drawing');

    const newItem = createDrawing(allWorkspaceItems, user);

    openWorkspaceItemPanel({ item: newItem });
    closeLibrary();
  };

  if (smScreen || isEmbedded() || isFreeCordova(user)) return null;

  return (
    <Box className={classes.container}>
      <Badge
        color="secondary"
        variant="dot"
        overlap="circular"
        invisible={isDrawingPanelOpen}
        classes={{ badge: classes.badge }}
      >
        <Box mb={1}>
          <Tooltip title="Draw" placement="left">
            <Box component="span">
              <Fab
                aria-label="annotate the map"
                size="small"
                color="default"
                disabled={isDrawingPanelOpen}
                onClick={handleCreate}
              >
                <PaletteIcon />
              </Fab>
            </Box>
          </Tooltip>
        </Box>
      </Badge>
      {actions.map((action) => (
        <Box mb={1} key={action.ariaLabel}>
          <Tooltip title={action.tooltip} placement="left">
            <Box component="span">
              <Fab
                aria-label={action.ariaLabel}
                size="small"
                color={action.color as FabProps['color']}
                disabled={action.disabled}
                onClick={action.onClick}
              >
                {action.icon}
              </Fab>
            </Box>
          </Tooltip>
        </Box>
      ))}

      {/* TODO remove after 2024-10-01 */}
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box p={2}>
          <Alert severity="warning">
            Map Print Builder is no longer available.
            <br />
            Press <Code className={classes.code}>Ctrl + P</Code> to print the
            map directly.
          </Alert>
        </Box>
      </Popover>
    </Box>
  );
};
