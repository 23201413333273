import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Alert, Box, Button, IconButton, Stack } from '@mui/material';
import { AUTO_VISUALIZE_LIMIT, STREAMING_LIMIT } from '@pn/core/limits';
import { formatDataType } from '@pn/core/domain/data';
import { isPartialDataReceived } from '@pn/core/domain/workspace';
import { revisualizeWorkspaceItem } from '@pn/core/operations/workspace';
import {
  useMapStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { getMinZoomLevel } from '@pn/core/storage/workspace/minZoomLevels';
import { isEmbedded } from '@pn/core/utils/embedded';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';
import { isNil } from 'lodash-es';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    textAlign: 'center',
    '@media print': {
      display: 'none',
    },
  },
  stack: {
    display: 'inline-flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2),
  },
  alert: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  alertButton: {
    marginLeft: theme.spacing(2),
  },
}));

/**
 * Displays all relevant map overlays for the selected workspace item.
 *
 * You may not need this in your project if you never set `ignoreLimit` to
 * false in your queries and don't provide streaming cancellation options.
 */
export const MapOverlay = () => {
  const { classes } = useStyles();

  const { isInitialized } = useMapStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();

  const { isWorkspaceItemPanelOpen } = useWorkspaceItemPanel();

  const [zoom, setZoom] = React.useState(Number.POSITIVE_INFINITY);
  React.useEffect(() => {
    if (!isInitialized) return;

    setZoom(map._native.getZoom()); // set initial zoom level
    map._native.on('zoomend', () => setZoom(map._native.getZoom()));
  }, [isInitialized]);

  const alerts = {
    isStreamingLimitHit: false,
    isAutoVisualizeLimitHit: false,
    isBelowMinZoomLevel: false,
    isPartialDataReceived: false,
  };

  alerts.isStreamingLimitHit =
    !isNil(workspaceItemSelected) &&
    !isNil(workspaceItemSelected.sourceItem) &&
    workspaceItemSelected.isRendered &&
    workspaceItemSelected.totalCount > STREAMING_LIMIT &&
    workspaceItemSelected.streamedCount === 0;

  alerts.isAutoVisualizeLimitHit =
    !isNil(workspaceItemSelected) &&
    !isNil(workspaceItemSelected.sourceItem) &&
    workspaceItemSelected.isRendered &&
    !alerts.isStreamingLimitHit &&
    workspaceItemSelected.totalCount > AUTO_VISUALIZE_LIMIT &&
    workspaceItemSelected.streamedCount === 0;

  alerts.isBelowMinZoomLevel =
    !isNil(workspaceItemSelected) &&
    workspaceItemSelected.isRendered &&
    zoom < getMinZoomLevel(workspaceItemSelected.id);

  alerts.isPartialDataReceived =
    !isNil(workspaceItemSelected) &&
    isPartialDataReceived(workspaceItemSelected);

  const noAlertsShown = Object.values(alerts).every((value) => !value);

  const handleZoomIn = () => {
    assert(
      workspaceItemSelected,
      'workspaceItemSelected has to be defined to zoom in'
    );
    map.setZoom(getMinZoomLevel(workspaceItemSelected.id), 500);
  };

  if (isEmbedded() || isNil(workspaceItemSelected) || isWorkspaceItemPanelOpen)
    return null;

  return (
    <Box
      className={classes.container}
      style={{ pointerEvents: noAlertsShown ? 'none' : undefined }}
    >
      <Stack spacing={2} className={classes.stack}>
        {alerts.isStreamingLimitHit && (
          <Alert variant="filled" severity="warning">
            Cannot display over {STREAMING_LIMIT.toLocaleString()}{' '}
            {formatDataType(workspaceItemSelected.dataType)}
            <Box component="span" className={classes.alertButton} />
          </Alert>
        )}

        {alerts.isAutoVisualizeLimitHit && (
          <Alert variant="filled" severity="info" icon={false}>
            {workspaceItemSelected.totalCount.toLocaleString()}{' '}
            {formatDataType(workspaceItemSelected.dataType)} were matched{' '}
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              className={classes.alertButton}
              onClick={() =>
                workspaceActions().overrideLimit(workspaceItemSelected.id)
              }
            >
              Show
            </Button>
          </Alert>
        )}

        {alerts.isPartialDataReceived && (
          <Alert variant="filled" severity="warning" icon={false}>
            {workspaceItemSelected.streamedCount.toLocaleString()} out of{' '}
            {workspaceItemSelected.totalCount.toLocaleString()}{' '}
            {formatDataType(workspaceItemSelected.dataType)} shown{' '}
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              className={classes.alertButton}
              onClick={() => revisualizeWorkspaceItem(workspaceItemSelected)}
            >
              Retry
            </Button>
          </Alert>
        )}

        {alerts.isBelowMinZoomLevel && (
          <Alert
            variant="filled"
            severity="warning"
            className={classes.alert}
            icon={false}
          >
            Not all {formatDataType(workspaceItemSelected.dataType)} are shown
            at this zoom level{' '}
            <IconButton color="inherit" onClick={handleZoomIn}>
              <ZoomInIcon />
            </IconButton>
          </Alert>
        )}
      </Stack>
    </Box>
  );
};
