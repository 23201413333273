import type { DataItemId, MappingItem } from '@pn/core/domain/data';
import type { GeoBoundingBox } from '@pn/core/domain/geography';
import type { StreamableQuery } from '@pn/core/domain/query';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiQueryMapper } from '@pn/services/api/query';
import { isNil } from 'lodash-es';

type ApiResponse = {
  data_type: string;
  internal_ids: DataItemId[];
};

export const apiZoneProvider = {
  getByZone: async (params: {
    zone: string;
    open: boolean;
    bbox: GeoBoundingBox | undefined;
    query: StreamableQuery | undefined;
    mapping: MappingItem[];
  }) => {
    const { zone, open, bbox, query, mapping } = params;

    const apiQuery = !isNil(query)
      ? apiQueryMapper.parquet(mapping).toTargetQuery(query)
      : undefined;

    const { data_type: dataType, internal_ids: internalIds } =
      await pnApiClient.request<ApiResponse>({
        method: 'POST',
        url: 'v2/zone_query',
        payload: {
          open,
          formations: [zone],
          bounding_box: !isNil(bbox)
            ? {
                swlat: bbox.southWest.lat,
                swlng: bbox.southWest.lon,
                nelat: bbox.northEast.lat,
                nelng: bbox.northEast.lon,
              }
            : undefined,
          query: apiQuery,
        },
      });

    return { dataType, internalIds };
  },
};
