import RefreshIcon from '@mui/icons-material/Refresh';
import {
  IconButton,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import type { ExportJob } from '@pn/core/domain/export-job';
import { multiExportOptions } from '@pn/core/operations/export/multiExportOptions';
import { apiExportJobsProvider } from '@pn/services/api/export-job/apiExportJobsProvider';
import { format } from 'date-fns';
import { capitalize, isEmpty, isNil, take } from 'lodash-es';
import prettyBytes from 'pretty-bytes';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

type Row = ExportJob & { name: string };

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    height: 267, // fits 4 rows + header
  },
  tableRow: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  refreshCell: {
    padding: 0,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  dataType: {
    color: theme.palette.text.secondary,
  },
  textDisabled: {
    color: theme.palette.text.disabled,
  },
}));

export const ExportsTable = () => {
  const { classes } = useStyles();

  const [rows, setRows] = React.useState<Row[]>([]);
  const [isFetching, setIsFetching] = React.useState(true);

  const fetchExportJobs = React.useCallback(async () => {
    setRows([]);
    setIsFetching(true);

    // await wait(2000);
    const exportJobs = await apiExportJobsProvider.getExportJobs();
    const exportJobsRows = take(exportJobs, 100).map((job) => ({
      /* currently limited to 100 rows */
      ...job,
      name:
        multiExportOptions.find((option) => option.type === job.type)?.name ??
        job.type,
    }));

    setRows(exportJobsRows);
    setIsFetching(false);
  }, []);

  React.useEffect(() => {
    fetchExportJobs();
  }, [fetchExportJobs]);

  return (
    <Paper variant="outlined">
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="past exports">
          <TableHead>
            <TableRow>
              <TableCell width={300}>Export</TableCell>
              <TableCell width={150}>Created At</TableCell>
              <TableCell width={150}>Status</TableCell>
              <TableCell width={150}>Size</TableCell>
              <TableCell align="center" className={classes.refreshCell}>
                <IconButton title="Refresh" onClick={fetchExportJobs}>
                  <RefreshIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching &&
              Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell component="th" scope="row" colSpan={4}>
                    <Skeleton key={index} variant="rounded" height={20} />
                  </TableCell>
                </TableRow>
              ))}
            {rows.map((row) => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell} title={row.name}>
                  <span className={classes.dataType}>
                    {formatDataType(row.dataType, { case: 'sentence' })} /
                  </span>{' '}
                  {row.name}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {format(new Date(row.createdAt), 'MMM d, h:mm a')}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {capitalize(row.status)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {!isNil(row.size) ? (
                    prettyBytes(row.size)
                  ) : (
                    <span className={classes.textDisabled}>&nbsp;&ndash;</span>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Link href={row.url} target="_blank" rel="noreferrer">
                    Download
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            {isEmpty(rows) && (
              <TableRow className={classes.tableRow}>
                <TableCell colSpan={5} align="center">
                  No past exports found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
