import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { mapSlice } from './mapSlice';
import type { IMapActions, IMapStorage } from './ports';

export const useReduxMapStorage = (): IMapStorage => {
  const { name, getInitialState } = mapSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isInitialized: useSliceSelector((state) => state.isInitialized),
    mapMode: useSliceSelector((state) => state.mapMode),
    isRenderingLayers: useSliceSelector((state) => state.isRenderingLayers),
  };
};

export const reduxMapActions = (): IMapActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = mapSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    initialize: () => dispatch(actions.initialize()),
    updateMode: (mode) => dispatch(actions.updateMode(mode)),
    updateIsRenderingLayers: (isRenderingLayers) =>
      dispatch(actions.updateIsRenderingLayers(isRenderingLayers)),
    reset: () => dispatch(actions.reset()),
  };
};
