import CloseIcon from '@mui/icons-material/Close';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { formatDataType, getDirectionsPoint } from '@pn/core/domain/data';
import {
  getDataItemSelected,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { unselectDataItem } from '@pn/core/operations/dataSelection';
import { useRouter } from '@pn/core/operations/router';
import { useDataCounts } from '@pn/core/providers/data-info/useDataCounts';
import { useCurrentUserStorage } from '@pn/core/storage';
import { PN_LOG_VIEWER_URL, STACKDX_URL } from '@pn/core/urls';
import { isCordova, isFreeCordova } from '@pn/core/utils/env';
import { getDirectionsUrl } from '@pn/services/map/google/directions';
import { NavRail } from '@pn/ui/custom-components/nav-rail';
import { NavRailItem } from '@pn/ui/custom-components/nav-rail/NavRailItem';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { StackDXIcon } from '@pn/ui/icons/StackDXIcon';
import { isNil, last } from 'lodash-es';
import { notificationService } from 'src/application/externalDependencies';
import { MAIN_TRAY_WIDTH } from 'src/ui/Main';
import { getMainPanelRoutes } from 'src/ui/main-panel/routes';
import { getMainPanelUri, openInNewTab } from 'src/ui/main-panel/utils';

type Props = {
  item: WorkspaceItem;
};

export const Tray = ({ item }: Props) => {
  const { location } = useRouter();
  const { xsScreen } = useScreenSize();

  const { user, isStackUser } = useCurrentUserStorage();

  const dataItemSelected = getDataItemSelected(item);
  const counts = useDataCounts({
    dataType: item.dataType,
    id: item.requestedDataItem.id,
  });

  const handleOpenDirections = () => {
    if (isNil(dataItemSelected)) {
      notificationService.notify('Please wait for the data to load');
      return;
    }

    if (isNil(dataItemSelected.geoShape)) {
      notificationService.notify(
        `${formatDataType(item.dataType, {
          form: 'singular',
          case: 'sentence',
        })} coordinates not found`
      );
      return;
    }

    const geoPoint = getDirectionsPoint(dataItemSelected, item.dataType);
    openInNewTab(getDirectionsUrl(geoPoint));
  };

  const handleOpenPNLogViewer = () => {
    openInNewTab(PN_LOG_VIEWER_URL + '/?wellId=' + item.requestedDataItem.id);
  };

  return (
    <NavRail width={MAIN_TRAY_WIDTH}>
      {!xsScreen && (
        <NavRailItem
          Icon={CloseIcon}
          label="Close"
          onClick={() => unselectDataItem(item)}
        />
      )}
      <NavRailItem
        Icon={DirectionsOutlinedIcon}
        label="Directions"
        onClick={handleOpenDirections}
      />
      {getMainPanelRoutes(item.dataType)
        .filter(
          (route) =>
            !route.isIntegration &&
            (isFreeCordova(user) ? route.title !== 'Documents' : true)
        )
        .map((route) => {
          const subpanel = last(route.path.split('/'))!;
          const selected = last(location.pathname.split('/')) === subpanel;

          return (
            <NavRailItem
              key={route.path}
              badgeActive={counts[subpanel] > 0}
              Icon={selected ? route.IconSelected : route.Icon}
              label={route.title}
              selected={selected}
              to={getMainPanelUri({
                dataType: item.dataType,
                dataItemId: item.requestedDataItem.id,
                subpanel,
              })}
            />
          );
        })}
      {!isFreeCordova(user) && !xsScreen && item.dataType === 'wells' && (
        <NavRailItem
          Icon={QueryStatsIcon}
          label="Log Viewer"
          onClick={handleOpenPNLogViewer}
        />
      )}
      {isStackUser &&
        ['wells', 'wells_usa'].includes(item.dataType) &&
        (isCordova() ? (
          <NavRailItem
            Icon={StackDXIcon}
            label="StackDX"
            onClick={() =>
              openInNewTab(
                `${STACKDX_URL}/PetroNinja/Well/${item.requestedDataItem.id}`
              )
            }
          />
        ) : (
          <NavRailItem
            Icon={StackDXIcon}
            label="StackDX"
            selected={last(location.pathname.split('/')) === 'stackdx'}
            to={getMainPanelUri({
              dataType: item.dataType,
              dataItemId: item.requestedDataItem.id,
              subpanel: 'stackdx',
            })}
          />
        ))}
    </NavRail>
  );
};
