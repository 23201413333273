import {
  getBoundingBox,
  toGeoPoint,
  geometryToGeoShape,
} from '@pn/core/domain/geography';
import type { ISearchResultMapper } from '@pn/core/mappers/search/ports';
import { isNil } from 'lodash-es';
import type { ApiSearchResult } from './types';

export const apiSearchResultMapper: ISearchResultMapper<ApiSearchResult> = {
  toDomainSearchResult: (apiSearchResult) => {
    if (apiSearchResult.result_category === 'location') {
      return {
        resultType: 'location',
        geoShape: geometryToGeoShape(apiSearchResult.geometry),
        label: apiSearchResult.label,
        bbox: !isNil(apiSearchResult.bbox)
          ? getBoundingBox([
              toGeoPoint(apiSearchResult.bbox[1], apiSearchResult.bbox[0]),
              toGeoPoint(apiSearchResult.bbox[3], apiSearchResult.bbox[2]),
            ])
          : undefined,
      };
    } else {
      return {
        _id: apiSearchResult.internal_id,
        dataType: apiSearchResult.data_type,
        resultType: apiSearchResult.result_category,
        geoShape: apiSearchResult.geometry
          ? geometryToGeoShape(apiSearchResult.geometry)
          : undefined,
        province: stateToCode(apiSearchResult.province),
        label: apiSearchResult.label,
        secondaryLabel: apiSearchResult.secondary_label,
      };
    }
  },
};

function stateToCode(arg: string): string {
  if (arg.length === 2) return arg.toUpperCase();

  switch (arg.toLowerCase()) {
    case 'alabama':
      return 'AL';
    case 'alaska':
      return 'AK';
    case 'arizona':
      return 'AZ';
    case 'arkansas':
      return 'AR';
    case 'california':
      return 'CA';
    case 'colorado':
      return 'CO';
    case 'connecticut':
      return 'CT';
    case 'delaware':
      return 'DE';
    case 'florida':
      return 'FL';
    case 'georgia':
      return 'GA';
    case 'hawaii':
      return 'HI';
    case 'idaho':
      return 'ID';
    case 'illinois':
      return 'IL';
    case 'indiana':
      return 'IN';
    case 'iowa':
      return 'IA';
    case 'kansas':
      return 'KS';
    case 'kentucky':
      return 'KY';
    case 'louisiana':
      return 'LA';
    case 'maine':
      return 'ME';
    case 'maryland':
      return 'MD';
    case 'massachusetts':
      return 'MA';
    case 'michigan':
      return 'MI';
    case 'minnesota':
      return 'MN';
    case 'mississippi':
      return 'MS';
    case 'missouri':
      return 'MO';
    case 'montana':
      return 'MT';
    case 'nebraska':
      return 'NE';
    case 'nevada':
      return 'NV';
    case 'new hampshire':
      return 'NH';
    case 'new jersey':
      return 'NJ';
    case 'new mexico':
      return 'NM';
    case 'new york':
      return 'NY';
    case 'north carolina':
      return 'NC';
    case 'north dakota':
      return 'ND';
    case 'ohio':
      return 'OH';
    case 'oklahoma':
      return 'OK';
    case 'oregon':
      return 'OR';
    case 'pennsylvania':
      return 'PA';
    case 'rhode island':
      return 'RI';
    case 'south carolina':
      return 'SC';
    case 'south dakota':
      return 'SD';
    case 'tennessee':
      return 'TN';
    case 'texas':
      return 'TX';
    case 'utah':
      return 'UT';
    case 'vermont':
      return 'VT';
    case 'virginia':
      return 'VA';
    case 'washington':
      return 'WA';
    case 'west virginia':
      return 'WV';
    case 'wisconsin':
      return 'WI';
    case 'wyoming':
      return 'WY';
    case 'district of columbia':
      return 'DC';
    case 'american samoa':
      return 'AS';
    case 'guam':
      return 'GU';
    case 'northern mariana islands':
      return 'MP';
    case 'puerto rico':
      return 'PR';
    case 'united states virgin islands':
      return 'VI';
    default:
      return arg;
  }
}
